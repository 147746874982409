export const TOKEN = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')

export const request = async(url, method, body, return_raw_response) => {
  const isFormData = body instanceof FormData

  const headers = isFormData
    ? { Accept: '*/*', 'X-CSRF-Token': `${TOKEN}` }
    : {
        Accept: '*/*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': `${TOKEN}`
      }

  const response = await fetch(url, {
    method,
    headers: headers,
    credentials: 'include',
    body: isFormData ? body : body ? JSON.stringify({ ...body, authenticity_token: TOKEN }) : null
  }).then(checkStatus).catch(err => console.log(err))

  return !return_raw_response && response ? response.json() : response
}

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response
  }
  else {
    const error = new Error(`HTTP Error ${response.statusText}`)

    error.status = response.statusText
    error.response = response
    console.log(error)
    return error
  }
}
