import {
  set_current_assignment,
  set_mapping_status_filters,
  set_entity_types,
  set_data_types,
  set_fields_for_upload,
  set_error_message
} from '../redux/reducers/general'

const initializeGeneralProps = (dispatch, props) => {
  dispatch(set_current_assignment(props.current_assignment))
  dispatch(set_mapping_status_filters(props.mapping_status_filters))
  dispatch(set_entity_types(props.entity_types))
  dispatch(set_data_types(props.data_types))
  dispatch(set_fields_for_upload(props.fields_for_upload))
  dispatch(set_error_message(props.error_message))
}

export default initializeGeneralProps
