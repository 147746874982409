const getGeneralState = store => store.general

export const getCurrentAssignment = store => (getGeneralState(store) ? getGeneralState(store).current_assignment : null)
export const getCurrentMappingEntityType = store => (getGeneralState(store) ? getGeneralState(store).current_mapping_entity_type : null)
export const getMappingStatusFilters = store => (getGeneralState(store) ? getGeneralState(store).mapping_status_filters : null)
export const getEntityTypes = store => (getGeneralState(store) ? getGeneralState(store).entity_types : null)
export const getDataTypes = store => (getGeneralState(store) ? getGeneralState(store).data_types : null)
export const getFields = store => (getGeneralState(store) ? getGeneralState(store).fields_for_upload : null)

export const getErrorMessage = store => (getGeneralState(store) ? getGeneralState(store).error_message : null)
