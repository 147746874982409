import { createSlice } from '@reduxjs/toolkit'

export const generalSlice = createSlice({
  name: 'general',
  initialState: {
    current_assignment: null,
    current_mapping_entity_type: null,
    mapping_status_filters: [],
    entity_types: [],
    data_types: [],
    fields_for_upload: [],
    error_message: null
  },
  reducers: {
    set_current_assignment: (state, action) => {
      state.current_assignment = action.payload
    },
    set_current_mapping_entity_type: (state, action) => {
      state.current_mapping_entity_type = action.payload
    },
    set_mapping_status_filters: (state, action) => {
      state.mapping_status_filters = action.payload
    },
    set_entity_types: (state, action) => {
      state.entity_types = action.payload
    },
    set_data_types: (state, action) => {
      state.data_types = action.payload
    },
    set_fields_for_upload: (state, action) => {
      state.fields_for_upload = action.payload
    },
    set_error_message: (state, action) => {
      state.error_message = action.payload
    }
  }
})

export const {
  set_current_assignment,
  set_current_mapping_entity_type,
  set_mapping_status_filters,
  set_entity_types,
  set_data_types,
  set_fields_for_upload,
  set_error_message
} = generalSlice.actions

export default generalSlice.reducer
