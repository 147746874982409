import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  getEntityTypes,
  getDataTypes,
  getFields
} from '../bundles/redux/selectors/generalSelector'
import { uploadFile } from '../utils/networkControllers/RailsRequests/AssignmentsController'
import { MenuItem, Button, TextField } from '@material-ui/core'
import Select from '@mui/material/Select'
import { startCase } from 'lodash'

const UploadFile = () => {
  const navigate = useNavigate()

  const entityTypes = useSelector(getEntityTypes) || []
  const dataTypes = useSelector(getDataTypes) || []
  const fields = useSelector(getFields) || []

  const [formData, setFormData] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)

  const handleBack = () => {
    navigate('/')
  }

  const handleFileUpload = () => {
    setLoading(true)

    const selectedOptions = {
      entity_type: formData.entity_type,
      data_type: formData.data_type,
      assignment_name: formData.assignment_name,
      remote_term_columns: formData.remote_term_columns.split('|').map(column => column.trim()),
      sheet_name: formData.sheet_name,
      file: formData.upload_file
    }

    if (formData.data_platform_term_column) {
      selectedOptions.data_platform_term_column = formData.data_platform_term_column
    }

    uploadFile(selectedOptions)
      .then(response => {
        if (response.success) {
          setErrorMessage(null)
          setSuccessMessage('File uploaded successfully!')
          setFormData({})
        } else {
          setSuccessMessage(null)
          setErrorMessage(response.status)
        }

        setLoading(false)
        })
    .catch(error => {
      setErrorMessage('An error occurred while uploading file.')
      setSuccessMessage(null)
      console.error('Error uploading file:', error)
    })
  }

  const renderInputField = (field) => {
    switch (field.type) {
    case 'file':
      return (
        <input
          type='file'
          accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          required={field.required}
          onChange={(e) => setFormData({ ...formData, [field.name]: e.target.files[0] })}
        />
      )

    case 'dropdown':
      const options = field.name === 'entity_type' ? entityTypes : dataTypes

      return (
        <Select
          style={{
            flex: 1,
            fontSize: '14px',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
          }}
          value={formData[field.name] || ''}
          required={field.required}
          onChange={(e) => setFormData({ ...formData, [field.name]: e.target.value })}
          disabled={!options.length}
          displayEmpty
        >
          <MenuItem value='' disabled={!options.length}>- Select {field.title} -</MenuItem>
          {options.map(option => (
            <MenuItem
              key={option}
              value={option}
              style={{ fontSize: '14px' }}
            >
              {startCase(option)}
            </MenuItem>
          ))}
        </Select>
      )

    case 'text':
    default:
      return (
        <TextField
          style={{ flex: 1 }}
          InputProps={{ style: { fontSize: '14px' } }}
          value={formData[field.name] || ''}
          required={field.required}
          onChange={(e) => setFormData({ ...formData, [field.name]: e.target.value })}
        />
      )
    }
  }

  return (
    <>
      <div className='upload-container'>
        {successMessage && (
          <center className='success-message' style={{ width: 'auto', marginLeft: '30%', marginRight: '30%', marginBottom: '30px'}}>{successMessage}</center>)}
        {errorMessage && (
          <center className='error-message' style={{ width: 'auto', marginLeft: '30%', marginRight: '30%', marginBottom: '30px'}}>{errorMessage}</center>
        )}
        {fields.map(field => (
          <div className='labeled-row'>
            <label className='label'>{field.title}</label>
            {renderInputField(field)}
          </div>
        ))}
        <div className='button-container'>
          <Button
            className='upload-file-button'
            style={{
              padding: '10px',
              marginLeft: '10px',
              marginTop: '30px',
              backgroundColor: '#ffc700',
              borderRadius: '5px',
              fontSize: '13px',
              color: 'white',
              fontFamily: "'Comfortaa', sans-serif",
              opacity: !formData.upload_file ? 0.6 : 1
            }}
            disabled={!formData.upload_file}
            onClick={handleFileUpload}
          >
            <i className='fa-solid fa-upload margin-right-10'></i>
            <span className='text' style={{ textTransform: 'none' }}>
              Upload File
            </span>
          </Button>
        </div>
      </div>
      <center>
        <Button
          className='back-button'
          style={{
            padding: '6px 8px 6px 8px',
            marginLeft: '10px',
            backgroundColor: '#ffc700',
            borderRadius: '5px',
            fontFamily: "'Comfortaa', sans-serif",
            color: 'white',
            fontSize: '13px'
          }}
          onClick={handleBack}
        >
        <i className='fa-solid fa-circle-left'></i>
        <span className='text' style={{ textTransform: 'none' }}>
          Back
        </span>
        </Button>
      </center>
    </>
  )
}

export default UploadFile
