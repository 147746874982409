import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getErrorMessage } from '../bundles/redux/selectors/generalSelector'
import { set_current_assignment } from '../bundles/redux/reducers/general'
import {
  setInSession,
  getAssignments,
  renameAssignment,
  deleteAssignment
} from '../utils/networkControllers/RailsRequests/AssignmentsController'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button
} from '@material-ui/core'
import Loading from './Loading'
import ActionDetailsPopUp from './ActionDetailsPopUp'
import ReactPaginate from 'react-paginate'
import Grid from '@mui/material/Grid'
import { startCase } from 'lodash'

const AssignmentSelection = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [reload, setReload] = useState(false)
  const [displayedData, setDisplayedData] = useState([])
  const [pageCount, setPageCount] = useState(0)

  const error_message = useSelector(getErrorMessage)
  const [errorMessage, setErrorMessage] = useState(null)

  const storedAssignmentFilter = localStorage.getItem('AssignmentStatusFilter')
  const storedAssignmentsCurrentPage = localStorage.getItem('AssignmentsCurrentPage')
  const storedAssignmentItemsPerPage = localStorage.getItem('AssignmentItemsPerPage')

  const [showPopup, setShowPopup] = useState(false)
  const [popupType, setPopupType] = useState(null)
  const [selectedAssignment, setSelectedAssignment] = useState(null)

  const itemsPerPageOptions = [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '30', value: 30 }
  ]

  const statusFilterOptions = [
    { label: 'Pending', value: 'pend' },
    { label: 'Completed', value: 'compl' }
  ]

  const [filter, setFilter] = useState(storedAssignmentFilter|| statusFilterOptions[0].value)
  const [currentPage, setCurrentPage] = useState(parseInt(storedAssignmentsCurrentPage, 10) || 0)
  const [itemsPerPage, setItemsPerPage] = useState(parseInt(storedAssignmentItemsPerPage, 10) || itemsPerPageOptions[0].value)

  const columns = [
    { key: 'name', value: 'Title' },
    { key: 'created_by', value: 'Created By' },
    { key: 'actions', value: 'Actions' }
  ]

  const handleChange = (event) => {
    const filter = event.target.value

    setReload(true)
    setLoading(true)
    setFilter(filter)
    setCurrentPage(0)
    localStorage.setItem('AssignmentStatusFilter', filter)
    localStorage.setItem('AssignmentsCurrentPage', 0)
  }

  const handlePageChange = (selected) => {
    setCurrentPage(selected.selected)
    localStorage.setItem('AssignmentsCurrentPage', selected.selected)
  }

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10)

    setItemsPerPage(newItemsPerPage)
    setCurrentPage(0)
    localStorage.setItem('AssignmentItemsPerPage', newItemsPerPage)
  }

  const handleTitleClick = (assignment) => {
    setInSession(assignment)
      .then(response => {
        dispatch(set_current_assignment(assignment))

        navigate('/mapping')
       })
      .catch(error => {
        console.error('Error setting assignment:', error)
      })
  }

  const handleOpenPopup = (type, assignment) => {
    setPopupType(type)
    setSelectedAssignment(assignment)
    setShowPopup(true)
  }

  const handleClosePopup = () => {
    setShowPopup(false)
    setPopupType(null)
    setSelectedAssignment(null)
  }

  const handleConfirm = (newName) => {
    setReload(true)

    if (popupType === 'delete') {
      handleDelete(selectedAssignment)
    } else if (popupType === 'rename' && newName) {
      handleRename({ ...selectedAssignment, name: newName })
    }

    handleClosePopup()
  }

  const handleRename = (assignment) => {
    renameAssignment(assignment.id, assignment.name)
      .then(response => {
        setReload(false)
      })
      .catch(error => {
        setErrorMessage(`An error occurred while renaming assignment ${assignment.name}.`)
        console.error('Error fetching assignments:', error)
      })
  }

  const handleDelete = (assignment) => {
    deleteAssignment(assignment.id, assignment.name)
      .then(response => {
        setReload(false)
      })
      .catch(error => {
        setErrorMessage(`An error occurred while deleting assignment ${assignment.name}.`)
        console.error('Error fetching assignments:', error)
      })
  }

  const valuesFormat = (value, key, index, resultsTableData) => {
    const row = resultsTableData[index]

    switch (key) {
    case 'name': {
      return (
        <span
          onClick={() => handleTitleClick(row)}
          style={{
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
        >
          {value}
        </span>
      )

    }
    case 'created_by': {
      return (
        <span>
          {startCase(value.username)}
        </span>
      )
    }
    case 'actions': {
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          height: '100%'
        }}>
          <Button
            style={{
              width: '25px',
              height: '25px',
              minWidth: '25px',
              minHeight: '25px',
              marginLeft: '-10px',
              marginRight: '10px',
              fontSize: '20px'
          }}
          title={'Rename assignment'}
          onClick={() => handleOpenPopup('rename', row)}
        >
            <i className='fa-solid fa-pen-to-square'></i>
          </Button>
          <Button
            style={{
              width: '25px',
              height: '25px',
              minWidth: '25px',
              minHeight: '25px',
              fontSize: '20px'
            }}
            title='Delete assignment'
            onClick={() => handleOpenPopup('delete', row)}
          >
            <i className='fa-solid fa-trash'></i>
          </Button>
        </div>
        )
    }
    default:
      return value
    }
  }

  useEffect(() => {
    getAssignments(filter, itemsPerPage, currentPage + 1)
      .then(response => {
        const { assignments, total_count } = response

        setDisplayedData(assignments)
        setPageCount(Math.ceil(total_count / itemsPerPage))
        setLoading(false)
        setReload(false)
      })
      .catch(error => {
        setErrorMessage('An error occurred while fetching assignments.')
        console.error('Error fetching assignments:', error)
      })
  }, [filter, currentPage, itemsPerPage, reload])

  if (loading) {
    return (
      <Loading style={{ marginTop: '150px' }} />
    )
  } else {
    return (
      <div className='assignment-selection'>
        <div className='table-container' style={{ marginTop: '30px', marginLeft: '150px', marginRight: '150px', textAlign: 'center' }}>
          {error_message && (
            <center className='error-message' style={{ width: 'auto', marginLeft: '30%', marginRight: '30%', marginBottom: '30px'}}>{error_message}</center>
          )}
          {errorMessage && (
            <center className='error-message' style={{ width: 'auto', marginLeft: '30%', marginRight: '30%', marginBottom: '30px'}}>{errorMessage}</center>
          )}
          <Grid item>
            <FormControl component='fieldset'>
              <RadioGroup
                aria-label='status'
                name='controlled-radio-buttons-group-status'
                value={filter}
                onChange={handleChange}
              >
                <Grid container spacing={2} alignItems='center' justifyContent='center'>
                  {statusFilterOptions.map(option => (
                    <Grid item key={option.value}>
                      <span className='label-text'>{option.label}</span>
                      <FormControlLabel value={option.value} control={<Radio />} />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <label htmlFor='itemsPerPage' style={{ marginRight: '10px', fontWeight: 'bold' }}>Items per Page:</label>
            <select id='itemsPerPage' onChange={handleItemsPerPageChange} value={itemsPerPage}>
              {itemsPerPageOptions.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <table className='table table-striped fixed-table' key='mappings-table'>
            <thead className='transparent-header'>
              <tr>
                {columns.map(col => (
                  <th key={col.key} style={{ textAlign: 'center' }}>
                    <span style={{ marginRight: '15px' }}><strong>{col.value}</strong></span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {displayedData.length > 0 ? (
                displayedData.map((row, index) => (
                  <tr key={row.id}>
                    {columns.map(column => (
                      <td className='text-center capitalize' key={column.key}>
                        {valuesFormat(row[column.key], column.key, index, displayedData)}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={columns.length} style={{ textAlign: 'center', padding: '20px' }}> - No assignments available - </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className='pagination'>
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              breakLabel={<span>...</span>}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={1}
              onPageChange={(selected) => handlePageChange(selected)}
              containerClassName={'pagination'}
              activeClassName={'active'}
              pageClassName={'page-item'}
              previousClassName={'previous'}
              nextClassName={'next'}
              breakClassName={'break'}
              forcePage={currentPage}
            />
          </div>
          {showPopup && selectedAssignment && (
            <ActionDetailsPopUp
              details={selectedAssignment}
              type={popupType}
              open={showPopup}
              onClose={handleClosePopup}
              onConfirm={handleConfirm}
            />
          )}
        </div>
      </div>
    )
  }
}

export default AssignmentSelection
