import React from 'react'
import { useNavigate } from 'react-router-dom'

const MappingToolHeader = () => {
  const navigate = useNavigate()

  const handleHomeClick = (event) => {
    event.preventDefault()

    localStorage.removeItem('currentPage')
    localStorage.removeItem('itemsPerPage')
    localStorage.removeItem('statusFilter')

    navigate('/')
  }

  const handleUploadFileClick = (event) => {
    event.preventDefault()

    navigate('/upload_file')
  }

  return (
    <div className='header-container'>
      <div className='left-content'>
        <a
          href='/'
          title='Home'
          style={{
            textDecoration: 'none',
            color: 'inherit',
            maxWidth: '20%',
            minWidth: '300px'
          }}
          onClick={handleHomeClick}
          >
          <img src='/assets/home_button_logo.png' alt='Mapping Tool' style={{ maxWidth: '20%', minWidth: '300px' }}/>
        </a>
      </div>
      <div className='right-content'>
        <a
          href='/'
          title='Upload File'
          onClick={handleUploadFileClick}
          className='upload-link'>
          <i className='fa-solid fa-upload margin-right-10'></i><span className='text'>Upload File</span>
        </a>
        <a href='https://api.mapping-tool.foodakai.com/admin/' target='_blank' className='admin-link'>
          <i className='fa-solid fa-user-tie margin-right-10'></i><span className='text'>Admin</span>
        </a>
        <a href='/logout' className='logout-link'>
          <i className='fa fa-power-off margin-right-10'></i><span className='text'>Logout</span>
        </a>
      </div>
    </div>
  )
}

export default MappingToolHeader
