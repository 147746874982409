import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { Button, TextField } from '@material-ui/core'

const ActionDetailsPopUp = ({ details, type, open, onClose, onConfirm }) => {
  const [newName, setNewName] = useState(details?.name || '')

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        {type === 'delete' ? (
          <>
            <p style={{ fontFamily: "'Comfortaa', sans-serif" }}>
              Are you sure you want to delete '{details?.name}'?
            </p>
          </>
        ) : (
          <>
            <p style={{ fontFamily: "'Comfortaa', sans-serif" }}>
              {type === 'rename' ? `Enter new name for '{details?.name}:` : 'Enter name for new remote term:' }
            </p>
            <TextField
              fullWidth
              variant='outlined'
              value={newName}
              InputProps={{
                style: { fontSize: '12px', fontFamily: "'Comfortaa', sans-serif", padding: '5px 10px' }
              }}
              onChange={(e) => setNewName(e.target.value)}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          style={{
            color: 'gray',
            fontSize: '12px',
            fontFamily: "'Comfortaa', sans-serif",
            textTransform: 'none'
          }}
        >
          Cancel
        </Button>
        <Button
          style={{
            float: 'right',
            padding: '6px 8px 6px 8px',
            marginLeft: '10px',
            backgroundColor: '#ffc700',
            borderRadius: '5px',
            fontFamily: "'Comfortaa', sans-serif",
            color: 'white',
            fontSize: '12px',
            textTransform: 'none'
          }}
          onClick={() => onConfirm(type === 'delete' ? null : newName)}
        >
          {type === 'delete' ? 'Delete' : type === 'rename' ? 'Rename' : 'Add Term'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ActionDetailsPopUp
