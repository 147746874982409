import React from 'react'
import { BrowserRouter as Router, Routes , Route, Link } from 'react-router-dom'
import initializeGeneralProps from '../bundles/utils/initializeGeneralPropsStore.js'
import configureStore from '../bundles/redux/config'
import { Provider } from 'react-redux'
import MappingToolHeader from './MappingToolHeader'
import AssignmentSelection from './AssignmentSelection'
import Mapping from './Mapping'
import UploadFile from './UploadFile'

const App = props => {
  const store = configureStore()

  initializeGeneralProps(store.dispatch, props)

  return (
    <Provider {...props} store={store}>
      <Router>
        <MappingToolHeader path={'*'} />
        <Routes>
          <Route path='/' element={<AssignmentSelection />} />
          <Route path='/mapping' element={<Mapping />} />
          <Route path='/upload_file' element={<UploadFile />} />
        </Routes>
      </Router>
    </Provider>
  )
}

export default App
