import React, { useRef,useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from '@material-ui/core'
import Grid from '@mui/material/Grid'
import InfoTooltip from './InfoTooltip'
import { set_current_mapping_entity_type } from '../bundles/redux/reducers/general'
import {
  getCurrentAssignment,
  getMappingStatusFilters
} from '../bundles/redux/selectors/generalSelector'
import {
  getData,
  asyncSearchForDataPlatformTerms,
  saveMatch,
  deleteMatch,
  checkForExistingValidatedMatch,
  updateRecord,
  applySafeMatch,
  updateStatus,
  addRemoteTerm,
  renameRemoteTerm,
  deleteRemoteTerm
} from '../utils/networkControllers/RailsRequests/AssignmentsController'
import RemoteAutocomplete from './RemoteAutocomplete'
import ActionDetailsPopUp from './ActionDetailsPopUp'
import Loading from './Loading'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DetailsPopUp from './DetailsPopUp'
import ReactPaginate from 'react-paginate'
import { startCase } from 'lodash'

const Mapping = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const assignment = useSelector(getCurrentAssignment)
  const availableStatusFilters = useSelector(getMappingStatusFilters)

  const statusFilterOptions = [
    { label: 'All', value: '' },
    ...availableStatusFilters.map(filter => ({
      label: startCase(filter),
      value: filter
    }))
  ]

  const itemsPerPageOptions = [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '30', value: 30 }
  ]

  const downshiftReference = useRef(null)
  const [displayedData, setDisplayedData] = useState([])
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [assignmentStatus, setAssignmentStatus] = useState(assignment.status)
  const [reload, setReload] = useState(false)
  const [itemReload, setItemReload] = useState(Array(displayedData.length).fill(false))

  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [showActionsPopup, setActionsShowPopup] = useState(false)
  const [actionsPopupType, setActionsPopupType] = useState(null)
  const [selectedTerm, setSelectedTerm] = useState(null)
  const [details, setDetails] = useState(null)
  const [existingMatchTitle, setExistingMatchTitle] = useState(null)
  const [remoteIdToInvalidate, setRemoteIdToInvalidate] = useState(null)
  const [currentRowId, setCurrentRowId] = useState(null)
  const [pageCount, setPageCount] = useState(0)

  const storedFilter = localStorage.getItem('statusFilter')
  const storedCurrentPage = localStorage.getItem('currentPage')
  const storedItemsPerPage = localStorage.getItem('itemsPerPage')

  const [filter, setFilter] = useState(storedFilter || statusFilterOptions[0].value)
  const [currentPage, setCurrentPage] = useState(parseInt(storedCurrentPage, 10) || 0)
  const [itemsPerPage, setItemsPerPage] = useState(parseInt(storedItemsPerPage, 10) || itemsPerPageOptions[0].value)

  const showDetailsPopup = (details) => {
    setDetails(details)
    setShowPopup(true)
  }

  const handleClosePopup = () => {
    setShowPopup(false)
    setDetails(null)
  }

  const handleStatusFilterChange = (event) => {
    const filter = event.target.value

    setFilter(filter)
    setCurrentPage(0)
    localStorage.setItem('statusFilter', filter)
    localStorage.setItem('currentPage', 0)

  }

  const handleOpenActionsPopup = (type, term) => {
    setActionsPopupType(type)
    setSelectedTerm(term)
    setActionsShowPopup(true)
  }

  const handleCloseActionsPopup = () => {
    setActionsShowPopup(false)
    setActionsPopupType(null)
    setSelectedTerm(null)
  }

  const handleConfirmActionsPopUp = (newName) => {
    setReload(true)

    if (actionsPopupType === 'delete') {
      handleDeleteTerm(selectedTerm)
    } else if (actionsPopupType === 'rename' && newName) {
      handleRenameTerm({ ...selectedTerm, name: newName })
    } else if (actionsPopupType === 'add_new_term' && newName) {
      handleAddNewTerm(newName)
    }

    handleCloseActionsPopup()
  }

  const handleAddNewTerm = (name) => {
    const type = displayedData[0].entity_type

    addRemoteTerm(name, type, assignment.id)
    .then(response => {
      setReload(false)
    })
    .catch(error => {
      setErrorMessage(`An error occurred while adding remote term '${name}'.`)
      console.error('Error adding remote term:', error)
    })
  }

  const handleRenameTerm = (term) => {
    renameRemoteTerm(term.id, term.name)
      .then(response => {
        setReload(false)
      })
      .catch(error => {
        setErrorMessage(`An error occurred while renaming remote term '${term.name}'.`)
        console.error('Error renaming remote term:', error)
      })
  }

  const handleDeleteTerm = (term) => {
    deleteRemoteTerm(term.id, term.name)
      .then(response => {
        setReload(false)
      })
      .catch(error => {
        setErrorMessage(`An error occurred while deleting remote term ${term.name}.`)
        console.error('Error deleting remote term:', error)
      })
  }

  const valuesFormat = (value, key, index, resultsTableData) => {
    const row = resultsTableData[index]

    switch (key) {
    case 'data_platform_term_name': {
      if (row[key]) {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ flexGrow: 1, textAlign: 'center' }}>
              {value}
            </div>
          </div>
        )
      } else {
        return (
          <Grid container spacing={2} justifyContent='center'>
            <Grid item>
              <center>
                <RemoteAutocomplete
                  onSelect={(selectedItem) => handleDataPlatformTermSelect(row.id, selectedItem?.id)}
                  asyncFetchFunction={asyncSearchForDataPlatformTerms}
                  reference={downshiftReference}
                />
              </center>
            </Grid>
          </Grid>
        )
      }
    }
    case 'details': {
      const details = row.details
      const detailEntries = Object.entries(details)
      const initialDetails = detailEntries.slice(0, 2)
      const hasMoreDetails = detailEntries.length > 2

      return (
        <div>
          {initialDetails.map(([key, val]) => (
            <li key={key}>{key}: {val}</li>
          ))}
          {hasMoreDetails && (
            <Button
              style={{
                padding: '3px 5px 3px 5px',
                marginTop: '5px',
                marginLeft: '10px',
                backgroundColor: '#bfb182',
                borderRadius: '5px',
                fontFamily: "'Comfortaa', sans-serif",
                color: 'white',
                fontSize: '9px',
                textTransform: 'none',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4)'
              }}
              onClick={() => showDetailsPopup(details)}
            >
              Show More
            </Button>
          )}
        </div>
      )
    }
    case 'mapping_actions': {
      return (
        itemReload[index] ? (
          <Loading style={{ maxWidth: '50px', maxHeight: '50px', background: 'inherit' }} />
        ) : (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          height: '100%'
        }}>
          <Button
            style={{
              width: '25px',
              height: '25px',
              minWidth: '25px',
              minHeight: '25px',
              marginLeft: '-10px',
              marginRight: '10px',
              fontSize: '20px'
          }}
          title={row.mapping_status == 'valid' ? 'Unvalidate Mapping' : 'Validate Mapping'}
          onClick={() => handleStatusChange(index, row.id, row.mapping_status == 'valid' ? 'added' : 'valid')}
        >
            <i className={row.mapping_status == 'valid' ? 'fa-regular fa-circle-xmark' : 'fa-regular fa-circle-check' }></i>
          </Button>
          <Button
            style={{
              width: '25px',
              height: '25px',
              minWidth: '25px',
              minHeight: '25px',
              fontSize: '20px'
            }}
            title='Delete Dataplatform Term'
            onClick={() => handleMatch(index, row.id)}
          >
            <i className='fa-solid fa-trash'></i>
          </Button>
        </div>
        )
      )
    }
    case 'term_actions': {
      return (
        itemReload[index] ? (
          <Loading style={{ maxWidth: '50px', maxHeight: '50px', background: 'inherit' }} />
        ) : (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          height: '100%'
        }}>
          <Button
            style={{
              width: '25px',
              height: '25px',
              minWidth: '25px',
              minHeight: '25px',
              fontSize: '20px'
            }}
            title='Rename Term'
            onClick={() => handleOpenActionsPopup('rename', row)}
          >
            <i className='fa-solid fa-pen-to-square'></i>
          </Button>
          <Button
            style={{
              width: '25px',
              height: '25px',
              minWidth: '25px',
              minHeight: '25px',
              fontSize: '20px'
            }}
            title='Delete Term'
            onClick={() => handleOpenActionsPopup('delete', row)}
          >
            <i className='fa-solid fa-trash'></i>
          </Button>
        </div>
        )
      )
    }
    default:
      return value
    }
  }

  let columns = [
    { key: 'name', value: 'Remote Term' },
    { key: 'data_platform_term_name', value: 'Data Platform Term' },
    { key: 'details', value: 'Details' },
    { key: 'mapping_status', value: 'Status', info:
      '• Empty: Without mapping status<br/>' +
      '• Match: There is DataPlatform term with identical alternative name to remote term<br/>' +
      '• Added: A user has associate the remote term with a DataPlatform term manually<br/>' +
      '• Valid: There is DataPlatform term with identical name to remote term'
    },
    { key: 'mapping_actions', value: 'Mapping Actions', style: { width: '150px' } },
    { key: 'term_actions', value: 'Term Actions', style: { width: '150px' } }
  ]

  if (displayedData.length && displayedData[0].entity_type === 'supl') {
    columns.splice(2, 0, { key: 'rank', value: 'Rank' })
  }

  const handleDataPlatformTermSelect = (rowId, selectedItemId) => {
    saveMatch(rowId, selectedItemId)
      .then(response => {
        setErrorMessage(null)
        setReload(true)
      })
    .catch(error => {
      setErrorMessage('An error occurred while saving match.')
      console.error('Error saving match:', error)
    })
  }

  const handleStatusChange = (event, rowId, newStatus) => {
    if (newStatus == 'valid') {
      // Case when status changes to 'valid'
      checkForExistingValidatedMatch(rowId)
        .then(response => {
          if (response.remote_valid_term_id) {
            // Case when existing validated match found
            setShowConfirmation(true)
            setCurrentRowId(rowId)
            setRemoteIdToInvalidate(response.remote_valid_term_id)
            setExistingMatchTitle(response.title)
          } else {
            // Case when existing validated match not found
            updateRecordStatus(rowId, null, newStatus)
          }
        })
    } else {
      // Case when status changes to 'added'
      updateRecordStatus(rowId, null, newStatus)
    }
  }

  const updateRecordStatus = (rowId, remoteIdToInvalidate, status) => {
    updateRecord(rowId, remoteIdToInvalidate, status)
      .then(response => {
        setErrorMessage(null)
        setDisplayedData(prevDisplayedData => {
          const indexToUpdate = prevDisplayedData.findIndex(row => row.id === rowId)
          const { name } = prevDisplayedData[indexToUpdate]

          if (indexToUpdate !== -1) {
            const updatedData = [...prevDisplayedData]
            updatedData[indexToUpdate] = { ...updatedData[indexToUpdate], mapping_status: status }

            updatedData.forEach((row, index) => {
              if (row.name === name && row.mapping_status === 'valid' && row.id !== rowId) {
                updatedData[index] = { ...row, mapping_status: 'added' }
              }
            })

            return updatedData
          }
        })
      })
    .catch(error => {
      setErrorMessage('An error occurred while updating mapping status.')
      console.error('Error fetching assignment data:', error)
    })
  }

  const confirmUpdate = () => {
    updateRecordStatus(currentRowId, remoteIdToInvalidate, 'valid')
    setShowConfirmation(false)
  }

  const cancelUpdate = () => {
    setCurrentRowId(null)
    setRemoteIdToInvalidate(null)
    setExistingMatchTitle(null)
    setShowConfirmation(false)
  }

  const handleMatch = (index, rowId) => {
    deleteMatch(rowId)
      .then(response => {
        setErrorMessage(null)
        const newItemReload = [...itemReload]

        newItemReload[index] = true
        setItemReload(newItemReload)

        setReload(true)
      })
    .catch(error => {
      setErrorMessage('An error occurred while deleting match.')
      console.error('Error deleting match:', error)
    })
  }

  const handleSafeMatch = () => {
    filteredData = displayedData.filter(item => item.data_platform_term_id === null)

    applySafeMatch(filteredIds = filteredData.map(item => item.id))
      .then(response => {
        setLoading(true)
        setErrorMessage(null)
        setReload(true)
      })
    .catch(error => {
      setErrorMessage('An error occurred while fetching data platform terms.')
      console.error('Error fetching data platform terms:', error)
    })
  }

  const handleUpdateAssignmentStatus = () => {
    let status = assignmentStatus === 'pend' ? 'compl' : 'pend'

    updateStatus(assignment.name, status)
      .then(response => {
        setErrorMessage(null)
        setAssignmentStatus(status)
        setReload(true)
      })
    .catch(error => {
      setErrorMessage('An error occurred while finalizing assignment.')
      console.error('Error finalizing assignment:', error)
    })
  }

  const handleBack = () => {
    localStorage.removeItem('currentPage')
    localStorage.removeItem('itemsPerPage')
    localStorage.removeItem('statusFilter')

    navigate('/')
  }

  const handlePageChange = (selected) => {
    setCurrentPage(selected.selected)
    localStorage.setItem('currentPage', selected.selected)
  }

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10)

    setItemsPerPage(newItemsPerPage)
    setCurrentPage(0)
    localStorage.setItem('itemsPerPage', newItemsPerPage)
  }

  useEffect(() => {
    if (assignment) {
      getData(assignment.id, itemsPerPage, currentPage + 1, filter)
        .then(response => {
          const { data, total_count } = response

          setDisplayedData(data)
          setPageCount(Math.ceil(total_count / itemsPerPage))

          dispatch(set_current_mapping_entity_type(data[0]?.entity_type))
          setLoading(false)
          setReload(false)
          setItemReload(Array(displayedData.length).fill(false))
        })
        .catch(error => {
          setErrorMessage('An error occurred while fetching assignment data.')
          console.error('Error fetching assignment data:', error)
        })
    }
  }, [assignment, currentPage, filter, itemsPerPage, reload])

  if (loading) {
    return (
      <Loading style={{ marginTop: '150px' }} />
    )
  } else {
    if (displayedData) {
      return (
        <div className='table-container' style={{ marginTop: '30px', marginLeft: '150px', marginRight: '150px', textAlign: 'center' }}>
          {errorMessage && (
            <center className='error-message' style={{ width: 'auto', marginLeft: '30%', marginRight: '30%', marginBottom: '30px'}}>{errorMessage}</center>
          )}
          <div className='assignment-name'>
            Assignment: {assignment.name}
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              style={{
                padding: '10px',
                marginLeft: '10px',
                marginTop: '30px',
                marginBottom: '20px',
                backgroundColor: '#ffc700',
                borderRadius: '5px',
                fontFamily: "'Comfortaa', sans-serif",
                color: 'white',
                fontSize: '13px'
              }}
              title={
                'Check for:\n' +
                '• Existing Validated Mapping\n' +
                '• Identical Dataplatform Term\n' +
                '• Identical Alternative Name in Dataplatform\n' +
                '• Similar Dataplatform Term'
              }
              onClick={handleSafeMatch}
            >
              <span className='text' style={{ textTransform: 'none' }}>
                Safe Match
              </span>
            </Button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button
              style={{
                padding: '7px',
                backgroundColor: '#ffc700',
                borderRadius: '5px',
                fontFamily: "'Comfortaa', sans-serif",
                color: 'white',
                fontSize: '13px'
              }}
              onClick={() => handleOpenActionsPopup('add_new_term', null)}
            >
              <span className='text' style={{ textTransform: 'none' }}>
                Add Remote Term
              </span>
            </Button>
            <div style={{ display: 'flex',  alignItems: 'center' }}>
              <label htmlFor='itemsPerPage' style={{ marginRight: '10px', fontWeight: 'bold' }}>Items per Page:</label>
              <select id='itemsPerPage' onChange={handleItemsPerPageChange} value={itemsPerPage}>
                {itemsPerPageOptions.map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
          </div>
          <table className='table table-striped fixed-table' key='mappings-table'>
            <thead className='transparent-header'>
              <tr>
                {columns.map(col => (
                  <th key={col.key} style={{ textAlign: 'center', ...col.style }}>
                    <span style={{ marginRight: col.key === 'mapping_status' ? '' : '15px' }}>{col.value}</span>
                    {col.key === 'mapping_status' &&
                      <>
                        <InfoTooltip text={col.info} />
                        <span style={{ marginRight: '10px' }}>
                          <select value={filter} onChange={handleStatusFilterChange}>
                            {statusFilterOptions.map(status => (
                              <option key={status.value} value={status.value}>{status.label}</option>
                            ))}
                          </select>
                        </span>
                      </>
                    }
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {displayedData.length > 0 ? (
                displayedData.map((row, index) => (
                  <tr key={row.id}>
                    {columns.map(column => (
                      <td className='text-center capitalize' key={column.key}>
                        {valuesFormat(row[column.key], column.key, index, displayedData)}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={columns.length} style={{ textAlign: 'center', padding: '20px' }}></td>
                </tr>
              )}
            </tbody>
          </table>
          <div className='pagination'>
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              breakLabel={<span>...</span>}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={1}
              onPageChange={(selected) => handlePageChange(selected)}
              containerClassName={'pagination'}
              activeClassName={'active'}
              pageClassName={'page-item'}
              previousClassName={'previous'}
              nextClassName={'next'}
              breakClassName={'break'}
              forcePage={currentPage}
            />
          </div>
          {details && (
            <DetailsPopUp details={details} open={showPopup} onClose={handleClosePopup} />
          )}
          <ToastContainer />
          {showConfirmation && (
            <div className='confirmation-pop-up'>
              <p>Existing validated match found with term: <b>'{existingMatchTitle}'</b></p>
              <p>Do you want to continue and replace it?</p>
              <Button
                style={{
                  marginRight: '20px',
                  backgroundColor: '#ffc700',
                  borderRadius: '5px',
                  fontFamily: "'Comfortaa', sans-serif",
                  color: 'white',
                  fontSize: '13px'
                }}
                onClick={confirmUpdate}
              >
                <span className='text' style={{ textTransform: 'none' }}>
                Yes
                </span>
              </Button>
              <Button
                style={{
                  backgroundColor: '#ffc700',
                  borderRadius: '5px',
                  fontFamily: "'Comfortaa', sans-serif",
                  color: 'white',
                  fontSize: '13px'
                }}
                onClick={cancelUpdate}
              >
                <span className='text' style={{ textTransform: 'none' }}>
                  No
                </span>
              </Button>
            </div>
          )}
          {showActionsPopup && (
            <ActionDetailsPopUp
              details={selectedTerm}
              type={actionsPopupType}
              open={showActionsPopup}
              onClose={handleCloseActionsPopup}
              onConfirm={handleConfirmActionsPopUp}
            />
          )}
          <div className='buttons-container'>
            <Button
              className='back-button'
              style={{
                padding: '6px 8px 6px 8px',
                marginLeft: '10px',
                backgroundColor: '#ffc700',
                borderRadius: '5px',
                fontFamily: "'Comfortaa', sans-serif",
                color: 'white',
                fontSize: '13px'
              }}
              onClick={handleBack}
            >
              <i className='fa-solid fa-circle-left'></i>
              <span className='text' style={{ textTransform: 'none' }}>
                Back
              </span>
            </Button>
            <a href={`https://api.mapping-tool.foodakai.com/download-mapping?assignment_name=${assignment.name}`} target='_blank' className='download-link'>
              <i className='fa-solid fa-download margin-right-10'></i>
              <span className='text'>Download</span>
            </a>
            <Button
              className='update-assignment-status-button'
              style={{
                padding: '6px 8px 6px 8px',
                marginLeft: '10px',
                backgroundColor: '#ffc700',
                borderRadius: '5px',
                fontFamily: "'Comfortaa', sans-serif",
                color: 'white',
                fontSize: '13px'
              }}
              onClick={handleUpdateAssignmentStatus}
            >
              {assignmentStatus === 'pend' ? (
                <>
                  <i className='fa-solid fa-circle-check'></i>
                  <span className='text' style={{ textTransform: 'none' }}>
                    Complete
                  </span>
                </>
              ) : (
                <>
                  <i className='fa-solid fa-circle-xmark'></i>
                  <span className='text' style={{ textTransform: 'none' }}>
                    Pending
                  </span>
                </>
              )}
            </Button>
          </div>
        </div>
      )
    } else {
      return (
        <div className='table-container' style={{ marginTop: '100px', textAlign: 'center' }}>
          <table className='table table-striped' key='mappings-table'>
            <span>There are no data</span>
          </table>
          <Button
              className='back-button'
              style={{
                padding: '6px 8px 6px 8px',
                marginLeft: '10px',
                backgroundColor: '#ffc700',
                borderRadius: '5px',
                fontFamily: "'Comfortaa', sans-serif",
                color: 'white',
                fontSize: '13px'
              }}
              onClick={handleBack}
            >
              <i className='fa-solid fa-circle-left'></i>
              <span className='text' style={{ textTransform: 'none' }}>
                Back
              </span>
            </Button>
        </div>
      )
    }
  }
}

export default Mapping
