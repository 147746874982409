import  { request } from './RailsRequestsMainController'

export const setInSession = async (assignment) => {
  return request('/api/v1/assignments/set_in_session', 'post', { assignment: assignment })
}

export const getAssignments = async (status, page_size, page) => {
  return request('/api/v1/assignments/get_assignments', 'post', { status: status, page_size: page_size, page: page })
}

export const renameAssignment = async (id, name) => {
  return request('api/v1/assignments/rename_assignment', 'post', { assignment_id: id, assignment_name: name })
}

export const deleteAssignment = async (id, name) => {
  return request('api/v1/assignments/delete_assignment', 'post', { assignment_id: id, assignment_name: name })
}

export const getData = async (assignment_id, page_size, page, mapping_status) => {
  return request('/api/v1/assignments/get_data', 'post', { assignment_id: assignment_id, page_size: page_size, page: page,  mapping_status: mapping_status })
}

export const checkForExistingValidatedMatch = async (remote_id) => {
  return request('/api/v1/assignments/check_for_existing_validated_match', 'post', { remote_id: remote_id})
}

export const updateRecord = async (id, remoteIdToInvalidate, status ) => {
  body = {
   remote_id: id,
   remote_id_to_invalidate: remoteIdToInvalidate,
   status: status
  }

  return request('/api/v1/assignments/update_remote_term_status', 'post', body)
}

export const asyncSearchForDataPlatformTerms = async (input_text, entity_type) => {
  if (input_text.length < 3) return

  return request('/api/v1/assignments/search_for_data_platform_terms', 'post', { remote_name: input_text, entity_type: entity_type })
}

export const saveMatch = async (remote_id, data_platform_id) => {
  return request('/api/v1/assignments/save_match', 'post', { remote_id: remote_id, data_platform_id: data_platform_id })
}

export const deleteMatch = async (remote_id) => {
  return request('/api/v1/assignments/delete_match', 'post', { remote_id: remote_id })
}

export const applySafeMatch = async (ids) => {
  return request('api/v1/assignments/apply_safe_match_for_remote_terms', 'post', { remote_ids: ids })
}

export const updateStatus = async (assignment_name, status) => {
  return request('api/v1/assignments/update_status', 'post', { assignment_name: assignment_name, status: status })
}

export const addRemoteTerm = async (name, type, assignment_id) => {
  return request('api/v1/assignments/add_remote_term', 'post', { remote_term_name: name, type: type, assignment_id: assignment_id })
}

export const renameRemoteTerm = async (id, name) => {
  return request('api/v1/assignments/rename_remote_term', 'post', { remote_term_id: id, remote_term_name: name })
}

export const deleteRemoteTerm = async (id, name) => {
  return request('api/v1/assignments/delete_remote_term', 'post', { remote_term_id: id, remote_term_name: name })
}

export const uploadFile = async ({
  entity_type,
  data_type,
  remote_term_columns,
  sheet_name,
  data_platform_term_column,
  assignment_name,
  file,
}) => {
  const formData = new FormData()
  formData.append('entity_type', entity_type)
  formData.append('data_type', data_type)
  formData.append('remote_term_columns', remote_term_columns)
  formData.append('sheet_name', sheet_name)
  formData.append('file', file)

  if (assignment_name) {
    formData.append('assignment_name', assignment_name)
  }

  if (data_platform_term_column) {
    formData.append('data_platform_term_column', data_platform_term_column)
  }

  return request(
    'api/v1/assignments/upload_file',
    'post',
    formData
  )
}
